.payment-error {
  .error-block {
    align-items: center;
    bottom: 0;
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}