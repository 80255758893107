@import "../variables";


/* ShimmerEffect.css */

.shimmer-wrapper {
    overflow: hidden;
    position: relative;
  }
  
  .shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f3f3f3, #e0e0e0, #f3f3f3);
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;
  }
  
  .shimmer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 16px;
  }
  
  .shimmer-column {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .shimmer-image {
    width: 100%;
    height: 150px;
    background: linear-gradient(90deg, #f3f3f3, #e0e0e0, #f3f3f3);
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;
  }
  
  .shimmer-text {
    height: 16px;
    width: 80%;
    background: linear-gradient(90deg, #f3f3f3, #e0e0e0, #f3f3f3);
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;
    margin: 8px 0;
  }
  
  @keyframes shimmer {
    to {
      background-position: 200% 100%;
    }
  }
