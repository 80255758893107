* {
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Open", sans-serif;
}

html {
    scroll-behavior: smooth;
}

.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.header,
.ui.form input,
h1,
h2,
h3,
h4,
h5,
button,
input,
optgroup,
select,
textarea {
    /* font-family: "Montserrat", sans-serif !important; */
    font-family: "Open", sans-serif !important;
}

body {
    background-color: #e6edf4;
}

small {
    font-size: 70%;
    color: #999999;
}

.app-footer .ui.header.underline {
    border-bottom: 5px solid #1b97ae;
    padding: 0 0 4px;
}

.dragon {
    color: #b72730;
}

.glass {
    color: #1b97ae;
}

.app {
    min-height: 100vh;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.ui.segment.app-header {
    border-radius: 0;
    margin: 0;
}

.ui.segment.app-header .ui.menu {
    height: 40px;
}

.ui.segment.app-notification {
    border-radius: 0;
    margin: 0;
    background: #1b97ae;
    padding: 2px;
    color: #fff;
}

.ui.segment.announcement-blue {
    border-radius: 0;
    margin: 0;
    background: #1b97ae;
    padding: 2px;
    color: #fff;
}

.ui.segment.announcement-green {
    border-radius: 0;
    margin: 0;
    background: #09a309;
    padding: 2px;
    color: #fff;
}

.ui.segment.announcement-orange {
    border-radius: 0;
    margin: 0;
    background: orange;
    padding: 2px;
    color: #fff;
}

.ui.segment.announcement-red {
    border-radius: 0;
    margin: 0;
    background: red;
    padding: 2px;
    color: #fff;
}

.icon.close.close-notification {
    position: absolute;
    border: 0;
    cursor: pointer;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    right: 5px;
    top: 50%;
}

.page {
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: flex;
    min-height: calc(100vh - 80px);
}

.page-pricing {
    margin-top: 1rem;
}

.page-chart {
    min-height: calc(78vh - 80px);
}

input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type="range"] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #e6edf4;
    border-radius: 4px;
    border: 0.2px solid #010101;
}
input[type="range"]::-webkit-slider-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #e6edf4;
}
input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #e6edf4;
    border-radius: 4px;
    border: 0.2px solid #010101;
}
input[type="range"]::-moz-range-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type="range"]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type="range"]::-ms-fill-lower {
    background: #e6edf4;
    border: 0.2px solid #010101;
    border-radius: 4px;
}
input[type="range"]::-ms-fill-upper {
    background: #e6edf4;
    border: 0.2px solid #010101;
    border-radius: 4px;
}
input[type="range"]::-ms-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
    background: #e6edf4;
}
input[type="range"]:focus::-ms-fill-upper {
    background: #e6edf4;
}

.page-content {
    -webkit-box-flex: 1;
    flex: 1;
    /* to do */
    /* padding: 1rem !important; */
}

.panel {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 6px #3333331a;
    opacity: 1;
}

.title-image-block {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 6px #3333331a;
    opacity: 1;
}

.title-image-block .title-panel-header {
    color: #333333;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.panel .panel-header {
    color: #333333;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.panel .panel-header .panel-title {
    font-weight: 500;
    font-size: 18px;
    -webkit-box-flex: 1;
    flex: 1;
}

.panel .panel-header .panel-title.light {
    color: #555;
    font-size: 14px;
    font-weight: normal;
}

.ui.search > .ui.input > input {
    /* width: 100%; */
    box-shadow: 0px 3px 6px #3333331a;
    border: 1px solid #7070701a;
    opacity: 1;
}

.map {
    width: 100%;
    height: 300px;
}

@media screen and (max-width: 992px) {
    .map {
        height: 250px;
    }
}

.map-marker-tooltip > .count {
    color: #1b97ae;
    font-weight: 600;
}

.flex {
    display: -webkit-box;
    display: flex;
}

.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.flex-1 {
    -webkit-box-flex: 1;
    flex: 1;
}

.items-center {
    -webkit-box-align: center;
    align-items: center;
}

.content-center {
    -webkit-box-pack: center;
    justify-content: center;
}

.symbol {
    width: 0.6rem !important;
    margin: 0 0.3rem 0 0;
}

.header .symbol {
    width: 1.5rem;
}

.clickable {
    cursor: pointer;
}

.float-right {
    float: right;
}

.text-gray,
.text-muted {
    color: gray;
}

.text-red {
    color: #b72730;
}

.text-green {
    color: #62b63f;
}

.text-black {
    color: #000000 !important;
}

.bold {
    font-weight: bold !important;
}

i.icon.inverted.carousel-scroll {
    background-color: #1b97ae !important;
    border: 0;
    box-shadow: none;
}

.page.not-found .error-block {
    position: absolute;
    left: 0px;
    top: 72px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.page.not-found .error-heading {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 46px;
    line-height: 49px;
    font-weight: 800;
    text-align: left;
}

.ui.code-snippet {
    background: #f9fafb;
}

.ui.code-snippet .close {
    float: right;
}

.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu .link.item:hover,
.ui.inverted.menu a.item:hover,
.ui.link.inverted.menu .item:hover {
    background: #1b1c1d;
    color: #17aa1c;
}

.ui.table thead th {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.ui.table td {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    word-wrap: break-word;
}

.ui.basic.table thead th {
    &.darkColor {
        background: #969798 0% 0% no-repeat padding-box;
    }
    &.blackColor {
        background: #1b1c1d 0% 0% no-repeat padding-box;
        color: white;
    }
    background: #e6edf4 0% 0% no-repeat padding-box;
    padding: 1rem;
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) thead tr:first-child th {
    padding: 0.8rem;
}

.ui.buttons > .ui.dropdown:last-child .menu,
.ui.menu .right.dropdown.item .menu,
.ui.menu .right.menu .dropdown:last-child .menu.submenu {
    left: 110%;
}

.ui.basic.primary.button,
.ui.basic.primary.buttons .button {
    box-shadow: 0 0 0 1px #1b97ae inset !important;
    color: #1b97ae !important;
    border-color: #1b97ae;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background: #1b97ae;
    color: #fff !important;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
    background: #1b97ae;
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
    color: #fff !important;
}

.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
    background: #1b97ae 0% 0% no-repeat padding-box;
    color: #fff !important;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.secondary.button,
.ui.secondary.buttons .button {
    border-radius: 25px;
}

/* Login and Logout page */

.page.auth {
    background-image: url(./assets/images/dg-app-splash-dark.png);
}

/* .period-selector {
  width: 40px
} */

.auth #auth-form {
    background: #333333e6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3333331a;
    border: 1px solid #1b97ae;
    opacity: 1;
    padding: 20px 50px;
    /* width: 672px;
	height: 484px; */
}

@media screen and (max-width: 992px) {
    .auth #auth-form {
        padding: 20px 20px;
    }
}

#auth-form .title {
    height: 65px;
    font-size: 32px;
    font-weight: 200;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 0.73;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

#auth-form .title.small {
    font-size: 24px;
}

#auth-form .ui.form input {
    background: #ffffff1a 0% 0% no-repeat padding-box;
    color: #fff;
    /* border: 0.5px solid #efefef; */
}

#auth-form .footer {
    padding: 15px;
    text-align: center;
}

#auth-form .footer p {
    color: #ffffff;
}

/*
#auth-form .footer p a {
	color: #1b97ae;
}
*/

#formFields {
    padding: 10px;
}

.terms {
    font-size: 80%;
}

.logo {
    height: 32px;
}

.app-header .ui.dropdown .menu {
    background: #000000 0% 0% no-repeat padding-box;
    color: #fff !important;
}

.app-header .ui.dropdown .menu .item,
.app-header .ui.menu .ui.dropdown .menu > .item {
    color: #fff !important;
}

/* Menu styles */

.app-menu {
    position: fixed;
    left: 0px;
    width: 0px;
    height: 100vh;
    background: #282b3a;
    color: #ffffff;
}

.app-menu ul {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
}

.app-menu .ui.avatar.image {
    border-radius: 0;
    width: 24px;
    height: 24px;
    margin-right: 1em;
}

.menu__wrap {
    position: absolute;
    top: 3.5em;
    bottom: 0;
    overflow: hidden;
    width: 100%;
}

.menu__item {
    display: block;
    width: calc(100% - 50px);
    text-overflow: ellipsis;
    color: #ffffff;
}

.menu__item.active {
    background-color: #703dba;
    border-radius: 0 100px 100px 0;
}

.menu__link {
    font-weight: bold;
    position: relative;
    display: block;
    padding: 1em 0em 1em 3em;
    -webkit-transition: color 0.1s;
    transition: color 0.1s;
    cursor: pointer;
}

.menu__link:hover,
.menu__link:focus,
.menu__link[data-submenu]:hover::after,
.menu__link[data-submenu]:focus::after {
    color: #cccccc;
}

/* Open and close buttons */

.action {
    position: absolute;
    display: block;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    background: none;
}

.action:focus {
    outline: none;
}

.action--open {
    /* font-size: 1.5em; */
    /* top: 0.85em;
	left: 0.85em; */
    top: -0.2em;
    padding: 2px;
    /* margin-right: 1em; */
    display: none !important;
    color: #fff;
    /* position: fixed; */
    z-index: 1000;
}

.action--close {
    font-size: 1.1em;
    /* top: 1.25em;
	right: 1em; */
    /* display: none !important; */

    color: #fff;

    position: absolute;
    right: 10px;
    top: 10px;
    border: solid 1px #fff;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    padding: 4px;
}

@media screen and (max-width: 992px) {
    .action--open,
    .action--close {
        display: block !important;
    }

    .app-menu {
        z-index: 1000;
        top: 0;
        width: 100%;
        max-width: 100vw;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
    }

    .menu--open {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .app-header .nav-desktop {
        display: none !important;
    }
}

.ui.input-button__wrapper {
    border: 1px solid rgba(34, 36, 38, 0.14902);
    border-left: none;
    padding: 10px 2px;
    border-radius: 0px 4px 4px 0px;
    background: #fff;
}

.ui.input-button__wrapper > button {
    padding: 8px 12px;
}

.ui.small.horizontal.statistic > .value,
.ui.small.horizontal.statistics .statistic > .value {
    color: #333333;
    font-weight: 600;
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Open", sans-serif !important;
}

.ui.statistic > .label,
.ui.statistics .statistic > .label {
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Open", sans-serif !important;
    color: #333333;
    font-weight: 600;
    font-size: 12px;
}

.panel-stat {
    color: #333333;
    font-weight: 600;
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Open", sans-serif !important;
    font-size: 28px;
}

.ui.checkbox label {
    font-size: 11px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.normal-text {
    font-weight: normal;
}

.ui.statistic {
    margin: 0;
}

.text-center {
    text-align: center;
}

a,
.colored-link {
    cursor: pointer;
    color: #1b97ae;
}

a:hover,
.ui.dropdown:hover,
.colored-link:hover {
    color: #04697b !important;
}

.text-white {
    color: #fff;
}

.price-tooltip i.icon.info-icon {
    margin-top: 2px !important;
    margin-left: 6px !important;
}

i.icon.info-icon {
    color: #1b97ae;
    background: #e6edf4;
    position: absolute;
    margin-left: 5px;
    border: 0 !important;
}

.info-content {
    font-size: 12px !important;
}

.info-content-right {
    font-size: 12px !important;
    position: relative;
    left: 72%;
}

.balance-info {
    font-size: 12px;
}

.share-param {
    display : flex !important;
    flex-direction: row-reverse !important;
}

.nft-bottom {
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.nft-code-snippet {
    background: #f9fafb !important;
    width: 700px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .transaction-table {
        overflow-x: scroll;
    }
}

@media screen and (max-width: 1024px) {
    .nft-code-snippet {
        width: 600px !important;
    }
}

@media screen and (max-width: 820px) {
    .nft-code-snippet {
        width: 400px !important;
    }
}

@media screen and (max-width: 575px) {
    .nft-code-snippet {
        width: 220px !important;
    }
}

@media screen and (min-width: 768px) {
    .summary-balance {
        border-left: 1px solid #999999;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 575px) {
    .ui.pagination.menu {
        font-size: 0.7rem;
    }
}

.ui.form .react-phone-number-input input[type="tel"] {
    height: auto;
}

@media screen and (max-width: 768px) {
    .ui.stackable.grid > .column.no-padding-mobile,
    .ui.grid > .column.no-padding-mobile {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .price-tooltip i.icon.info-icon {
        margin-top: 3px !important;
    }
}

h3.section-subheading {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 60px;
    text-transform: none;
}

.service-details {
    padding: 80px 0 0 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: space-evenly;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .service-details {
        padding: 0;
    }
}

.service-details img {
    width: 4.5rem;
    height: 4.5rem;
}

h2.section-heading {
    font-size: 32px;
    font-weight: 500;
    /* margin-bottom: 60px; */
    text-transform: none;
}

.section-heading-underline hr {
    text-align: center;
    border-top: 5px solid;
}

.features .feature-item {
    padding: 40px 20px;
    text-align: center;
    background-color: #e0e0e0;
}

.features .feature-item-alt {
    background-color: #fff;
}

.feature-caption {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    width: auto;
    text-align: center;
}

.feature-column img {
    margin: 0 auto 1.5rem;
}

.market-desc {
    border-radius: 2em;
    margin: 20px;
    padding: 40px;
    background: #249bb3 0% 0% no-repeat padding-box;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .t-arrow {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        margin: auto;
    }
}

.intro .ui.header {
    font-size: 45px;
    font-weight: lighter;
}

@media screen and (max-width: 400px) {
    .get-started-mobile {
       padding-top: 25px !important;
    }
}

@media screen and (max-width: 768px) {
    .intro .ui.header {
        font-size: 25px;
    }

    .intro .dlt-logos {
        padding: 50px;
    }
}

.notification {
    background-color: #282b3a !important;
    width: 450px;
}

@media screen and (max-width: 440px) {
    .notification {
        width: 100vw;
    }
}

.notification-body {
    /* font-family: effra; */
    font-weight: bold;
}

/* PUBLIC API SELECTION */

.api-button-div {
    margin-top: 30px;
}

.api-button-small {
    width: 160px;
    font-size: 60% !important;
}

.ui.list.categories-list .item {
    cursor: pointer;
}

.ui.list.categories-list .item:hover {
    color: rgb(230, 45, 57);
    /* font-weight: bold; */
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.ui.list.categories-list .item.active {
    color: #1b97ae;
    font-weight: bold;
}

#padded {
    padding: 15px 0;
}

.urlBox {
    width: 98%;
    margin-left: 1%;
    border: 1px solid #249bb3;
    border-radius: 5px;
    background-color: #f8f9fa;
    padding: 10px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 95%;
    overflow-wrap: break-word;
}

.shortDescription {
    margin: 30px 0 0px 8px;
}

.longDescription {
    margin: 5px 0 5px 8px;
    font-size: 95%;
    font-weight: 500;
}

.introduction {
    margin-top: 25px;
    margin-bottom: 20px;
}

.parameter-table {
    width: 98%;
    margin-left: 1%;
}

.note {
    font-size: 80%;
}

.parameters-button {
    margin: -8px 0 20px 1%;
}

.header.parameters-toggle {
    margin: -8px 0 20px 1%;
}

.copy-icon {
    float: right;
    color: #1b97ae;
    cursor: pointer;
}

.copy-icon:hover {
    color: rgb(21, 128, 150);
    /* transition: all 0.2s ease-in-out; */
}

.copy-icon:active {
    /* color: red; */
    color: rgb(115, 199, 112);
    /* transition: all 0.2s ease-in-out; */
}

#early-adopter-selection {
    margin-top: 8px;
}

/* NEW PUBLIC API SELECTION */

/* a.anchor{display: block; position: relative; top: 100px;} */

.api-view-container {
    min-height: calc(100vh - 80px);
}

.selected {
    color: #1b97ae;
    font-weight: bold;
}

.try-it-button {
    width: 75px;
    height: 30px;
    font-weight: 700 !important;
    font-size: 75% !important;
}

.narrow {
    width: 10px;
}

.intro-title {
    font-size: 130%;
    margin-bottom: 12px;
    /* margin-top: 20px; */
}

.intro-title-small {
    font-size: 115%;
    margin-bottom: 8px;
    color: #2f5396;
    /* margin-top: 20px; */
}

.intro-paragraph {
    margin-bottom: 8px;
}

.intro-container {
    height: 100vh;
    overflow-y: scroll;
}

.intro-container::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1290px) and (min-width: 990px) {
    .wide-cell {
        font-size: 70% !important;
    }
}

@media screen and (max-width: 1170px) and (min-width: 990px) {
    .cell {
        font-size: 80% !important;
        overflow-wrap: anywhere;
    }
}

/* PROFILE PAGE */

.api-key-panel {
    margin-top: 20px;
}

.api-button {
    float: right;
}

.ui.modal .header {
    color: #1b97ae;
    font-size: 110%;
}

.ui.modal .header.modal-header {
    color: black;
    font-size: 180%;
    background-color: #e6edf4;
}

.ui.table .close {
    cursor: pointer;
}

.ui.table thead th {
    background-color: #e6edf4;
}

.toggle-state {
    cursor: pointer;
}

#early-adopter-profile {
    left: 540px;
    position: relative;
    top: 20px;
    width: 200px;
}

.subscribed-accounts-form {
    display: flex;
    justify-content: space-between;
}

.profile-button {
    width: 20px;
}

.subscription-button {
    float: right;
}

@media screen and (max-width: 1200px) {
    .ui.table.api-key-table thead th,
    .ui.table.api-key-table .api-cell {
        font-size: 80% !important;
    }

    .ui.table.api-key-table.subscriptions thead th {
        font-size: 79% !important;
    }

    #early-adopter-profile {
        left: 60%;
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .ui.table.api-key-table.subscriptions thead th {
        font-size: 58% !important;
    }

    .subscribed-accounts-form {
        flex-direction: column;
    }

    .subscriptions-header {
        display: flex;
        flex-direction: column;
    }

    .subscription-button {
        width: 200px;
    }

    .subscriptions-span {
        margin-bottom: 10px;
    }

    #early-adopter-profile {
        left: 55%;
    }
}

/* DAPP REGISTRATION FORM ON THE PROFILE PAGE */

.dropzone {
    width: 50%;
    border: 2px dashed #e5e5e5;
    height: 220px;
    border-radius: 15px;
    color: #767676;
    padding-top: 20px;
    margin-bottom: -30px;
}

.drop {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

/* DAPP */

#dapp-advanced {
    position: relative;
    right: 30px;
}

.dapp-copy-button {
    width: 55px;
    font-size: 80% !important;
}

.ui.table .cell-text {
    position: relative;
    left: 8px;
}

#green {
    color: #9dd187;
}

.white {
    color: white !important;
}

.white:hover {
    color: white !important;
}

#dapp-setting {
    position: relative;
    left: 650px;
    top: -20px;
    display: none;
}

.sample-logo {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.dapp-search {
    position: relative;
    left: 350px;
    top: -23px;
}

.dapp-menu-item {
    font-size: 85%;
    position: relative;
    top: -20px;
    /* left: -10px; */
    background: white;
}

.dapp-menu-item.second {
    left: -10px;
}

.dapp-menu-item.third {
    left: -18px;
}

#logo {
    width: 120px;
    margin-top: -10px;
}

.contract-selection {
    position: relative;
    top: -20px;
}

*:focus {
    outline: none !important;
}

.dapp-name {
    padding-left: 10px;
}

.dapp-name:hover {
    color: rgb(230, 45, 57);
    font-weight: bold;
    font-size: 105%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.logo-title {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.transactions {
    margin-top: -30px;
}

#dapp-desc-homepage:hover {
    color: #1b97ae;
    /* font-weight: 500; */
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.center {
    text-align: center;
}

.logo-container {
    width: 180px;
    height: 70px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.logo-image {
    height: 100%;
    max-width: 90%;
    -webkit-box-flex: 1;
    flex: 1;
    margin-right: 15px;
}

.dapp-summary-table {
    margin-left: -8px;
}

/* EVENT SUBSCRIPTION */

.data-period-selection {
    margin: 15px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.disabled {
    pointer-events: none;
}

/* CUSTOM PAGINATION */

.dappPaginator {
    display: -webkit-box;
    display: flex;
    margin-bottom: 25px;
}

.icon.angle {
    cursor: pointer;
}

/* SUBSCRIPTION AND DEPLOYMENT VIEW */

.description-text {
    line-height: 150%;
    margin-top: 15px;
}

.image-div {
    margin-left: 8%;
    margin-top: 20px;
}

.description-container {
    padding-bottom: 30px;
}

.paragraph-header {
    font-size: 130%;
    font-weight: 500;
}

.subintro {
    font-weight: 400 !important;
}

/* ------------------- */

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

/* CUSTOM TOOLTIP */

.hasTooltip div {
    display: none;
    color: #000;
    text-decoration: none;
    padding: 3px;
}

.hasTooltip:hover div {
    display: block;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: -5%;
    box-shadow: 2px 2px 2px #e6e6e6;
}

/* ------------------- */

/* ----------- MISC CORRECTIONS IN STYLING ----------- */

.item-hoverable {
    cursor: pointer;
}

.item-hoverable:hover {
    color: rgb(25, 115, 133);
}

.search-home-page .ui.form input::placeholder {
    font-size: 12px;
    color: #999;
}

/* ----------- HBAR PRICE ----------- */

.hbar-price {
    color: #333333;
    font-weight: 500;
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Open", sans-serif !important;
    font-size: 16px;
    line-height: 180%;
    display: flex;
}

/* ---------------------------------- */

/* ----------- TOPIC PAGE ----------- */

.topic-links {
    color: #50afc1;
}

.topic-links:hover {
    color: rgb(6, 151, 177);
    /* color: rgb(37, 122, 233); */
}

.recent-statements-link,
.recent-payments-link {
    padding: 20px;
    text-align: center;
}

.recent-statements-link > a,
.recent-payments-link > a {
    color: #333333;
    font-weight: 500;
}

/* .user-icon.massive.icon {
  font-size: 30px;
}

.user-icon.huge.icon {
  font-size: 25px;
} */

/* .ui.header.user-name {
  margin-bottom: 0;
} */

.monthly-payment-link {
    border-bottom: 2px solid #ccc;
}

.computer-only {
    display: none;
}

.mobile-only {
    display: none !important;
}

.hide-in-mobile {
    display: block !important;
}

@media (min-width: 992px) {
    .head-links {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .remaining-unit-text {
        padding-bottom: 20px;
    }

    .ui.header.remaining-unit-header {
        font-weight: 500;
        min-height: 120px;
        padding: 20px 0;
    }

    .monthly-msg-box {
        padding-top: 60px;
    }

    .msg-heading {
        padding: 0 60px 15px 60px;
    }

    .renewal-link-msg {
        font-size: 13px;
        padding-left: 40px;
    }

    .computer-only {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .ui.header.remaining-unit-header {
        padding-top: 10px;
    }

    .price-tooltip i.icon.info-icon {
        margin-top: 2px !important;
        margin-left: 1px !important;
    }

    .remaining-unit-text {
        padding-bottom: 10px;
    }

    .monthly-msg-box {
        padding-top: 10px;
    }

    .user-icon.massive.icon {
        font-size: 4em;
    }

    .user-icon.huge.icon {
        font-size: 2.5em;
    }

    .payment-history-wrapper .payments-list {
        overflow-x: scroll;
    }
    .tablet-only {
        display: block;
    }
}

.profile-header-link {
    margin-right: 75px;
}

.profile-header-link > a {
    color: #333333;
}

.accordion.ui i.icon,
.accordion.ui i.icons {
    font-size: 10px;
    font-weight: 300;
}

.table-no-border.ui.celled.table tr td {
    border-left: none;
}

.table-no-border.ui.celled.table tr.inner-row td,
.table-no-border.ui.celled.table tr.usage-details-row td {
    border: none;
}

.table-no-border.ui.celled.table tr.inner-row td {
    padding-bottom: 15px;
    padding-left: 30px;
}

.table-no-border.ui.celled.table tr.usage-details-row td {
    padding-bottom: 0;
    padding-top: 5px;
}

.usage-details-row td .empty-data {
    padding: 10px 0 10px 10px;
}

@media (max-width: 767px) {
    .ui.form .field.user-account-input .ui.input input {
        width: 100%;
    }

    .table-no-border.ui.table:not(.unstackable) tr.usage-details-row.no-border,
    .table-no-border.ui.table:not(.unstackable) tr.no-border {
        border: none !important;
        box-shadow: none !important;
    }

    .table-no-border.ui.table:not(.unstackable) tr.inner-row td:first-child,
    .table-no-border.ui.table:not(.unstackable) tr.usage-details-row td:first-child {
        font-weight: 400;
    }

    .usage-details-row .ui.grid .row {
        padding: 5px 5px 5px 10px;
    }

    .mobile-only {
        display: block !important;
    }

    .hide-in-mobile {
        display: none !important;
    }

    .title-image-block {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }
    .title-image-block .token-label-overview {
        margin-left: 4px;
    }
}

.banner-title {
    font-size: 16px;
    display: flex;
    align-items: center;
}
.banner-title>.img-space {
    padding: 0 10px;
}

.banner-title>.img-space.dg{
    margin-top: 10px;
}

.banner-title>.img-space.dropp{
    margin-bottom: -2px;
}

.banner-title>.img-space.dg-m{
    margin-top: 8px;
}

.banner-title>.img-space.dropp-m{
    margin-top: 5px;
}

.banner-title>.img-space:first-child {
    padding-left: 0;
}

.banner-title>.img-space:last-child {
    padding-right: 0;
}

.qr-txt {
    font-weight: 600;
}

@media (min-width: 991px) and (max-width: 1199px) {
    .qrtxt-box {
        padding-left: 5% !important;
    }
}

.qr-txt .scan-txt {
    color: #E7AB3F;
    line-height: 18px;
    padding-bottom: 5px;
}

@media (max-width: 767px) {
    .qr-info-m {
        text-align: center;
    }
}

.twitter-widget-embed {
    max-height: 400px !important;
    border-radius: 12px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.twitter-widget-embed::-webkit-scrollbar {
    display: none;
}

.hoverRow:hover {
    background-color: #cfcfcf;
}

.scroll-table-x {
    overflow-x: scroll;
}

.w-98 {
    width: 98% !important;
}

.w-100 {
    width: 100% !important;
}

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.font18 {
    font-size: 18px;
}

.font16 {
    font-size: 16px;
}

.font14 {
    font-size: 14px;
}

.font20 {
    font-size: 20px;
}

.mb-2 {
    margin-bottom: 2%;
}

.p-0 {
    padding: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.m-10 {
    margin: 10px !important;
}

/* Custom CSS for responsiveness */
@media screen and (max-width: 1000px) {
    .ui.stackable.menu {
        display: block !important;
    }
  }

  .checkbox-filter-facets.ui.checkbox input.hidden+label {
    word-break: break-word;
    word-wrap: break-word;
  }

  .inline-responsive {
    display: initial;
    padding-left: 2px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1265px) {
    .inline-responsive {
      display: flex;
    }
  }

  /* .explorerTabs {
      .ui.pointing.secondary.stackable.menu {
        font-size: 20px;
      }
  } */

  .visual {
    aspect-ratio: 1;
  }

  .react-thumbnail-generator {
    height: 0px;
    aspect-ratio: 1;
  }

  .video-thumbnail {
    height: 0px;
    width: calc(33.33% - 10px); /* Adjust thumbnail width as needed */
    /* margin-bottom: 20px; Add space between thumbnails */
  }

  .ui.message.nft-form-box {
    background-color: white;
    color: #333;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }