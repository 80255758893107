@import '../variables';

.page-pricing {
  .pricing-header {
    #dg-logo {
      display: inline-block;
      height: 47px;
      margin-right: 10px;
      width: 330px;

      @media (min-width: 767px) and (max-width: 991px) {
        width: 200px;
      }
    }

    .dropp-msg-section {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
    }

    .header-msg {
      padding: 0 0 0 8em;
      width: 80%;

      @media (max-width: 991px) {
        padding: 0;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        width: 100%;
      }
    }

    .dropp-logo {
      width: 105px;
    }
  }

  .row {
    .column {
      &.section {
        padding: 0 3em;
      }
    }
  }


  h3 {
    &.heading {
      margin-bottom: 7px;
      margin-top: 0;
      text-transform: uppercase;
    }

    &.header {
      &:first-child {
        margin-bottom: 7px;
        margin-top: 0;
      }
    }
  }

  h4 {
    &.ui.header {
      color: $link-color;
    }
  }

  .separator {
    margin: 0 2em;
    width: 100%;

    > div {
      border: .5px dashed $link-color;
      opacity: .25;
    }
  }

  .rc-slider {
    .rc-slider-rail {
      background-color: transparent;
      background-image: url('../../../assets/images/rectangle-slider-track@2x.png');
      background-position: center;
      height: 12px;
    }

    .rc-slider-handle {
      background-image: url('../../../assets/images/slider-handle@2x.png');
      background-position: center;
      background-size: cover;
      border: 0;
      border-color: transparent;
      height: 36px;
      margin-top: -10px;
      width: 28px;

      &:hover,
      &:active,
      &:focus,
      &.rc-slider-handle-click-focused {
        border: 0;
        border-color: transparent;
        box-shadow: none;
      }
    }

    .rc-slider-track {
      height: 12px;
    }

    @media (max-width: 767px) {
      width: 90% !important;
    }
  }

  .unit-box-wrapper {
    align-items: center;
    display: flex;

    .unit-box {
      background-color: $background-black-color;
      border: 3px solid $border-white-color;
      color: $background-white-color;
      display: inline-block;
      min-height: 70px;
      min-width: 200px;
      text-align: center;

      @media (max-width: 767px) {
        display: block;
      }
    }

    .bulk-pay-link {
      border-bottom: 1px solid $link-color;
      display: inline-block;
      margin-left: 15px;

      @media (max-width: 767px) {
        padding-top: 10px;
      }
    }

    @media (max-width: 767px) {
      display: block;
      text-align: center;
    }
  }

  .row.bib-txt {
    @media (max-width: 767px) {
      margin-top: 25px;
    }
  }

  .small-links {
    font-size: 10px;
  }

  .disable-payment-link {
    color: $payment-link-color;
  }

  .pricing-options {
    & > li {
      margin-right: 10%;
    }

    @media (min-width: 768px) {
      display: flex;
      margin-bottom: 0;
    }
  }

  .ui {
    &.header {
      & + p {
        margin-bottom: 5px;
      }
    }
  }
}

