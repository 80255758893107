@import "../variables";

.token-details {
    color: $text-light-color;
    font-size: 13px;

    .value {
        font-size: 13px;
        font-weight: 700;
        padding: 5px 0 10px;
        word-break: break-all;
    }
}

.token-detail-table {
    .cell-text {
        @media (min-width: 768px) {
            max-width: 135px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

#token-summary,
#token-overview {
    min-height: 240px;
}

#token-summary,
#token-overview,
#token-search-wrapper,
#token-transactions-table,
#token-transfers-table {
    &.table,
    table {
        td {
            border-left: 0;

            &.cell-value,
            &.collapsing {
                max-width: 300px;
                min-width: 135px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media (min-width: 768px) {
                    max-width: 135px;
                    min-width: 135px;
                }
            }
        }
    }
}

#token-summary,
#token-overview,
#token-search-wrapper,
#token-transactions-table,
#token-transfers-table {
    &.table,
    table {
        td {
            border-left: 0;

            &.cell-summ-val,
            &.collapsing {
                max-width: 300px;
                min-width: 135px;
                overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;

                @media (min-width: 768px) {
                    max-width: 135px;
                    min-width: 135px;
                }
            }
        }
    }
}
